.App {
  text-align: center;
  background-color: #202222;
  font-size: 20px;
}

body {
  overflow: hidden;
}

div.nopadding {
  padding-left: 0px;
  padding-right: 0px;
}

video {
  max-width: 100%;

  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

/* #absent_table > tbody> tr > td {
  color: pink;
} */

div.counter {
  color: snow;
}